<template>
    <section v-if="show">
        <div id="filter-job-vacancy-v2" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="closeModal"/>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Filter Applicant</h2>
                </div>
                <section class="uk-modal-body">
                    <div uk-grid>
                        <div class="uk-width-1-2">
                            <label>
                                <input v-model="filter.is_start_date" class="uk-checkbox" type="checkbox">
                                {{" "}}Req. Start Date
                            </label>
                            <div class="uk-form-controls">
                                <datetime
                                    v-model="filter.start_date"
                                    id="start_date"
                                    name="start_date"
                                    placeholder="dd/mm/yyy"
                                    input-class="uk-input"
                                    type='date'
                                    format="dd MMM yyyy"
                                    :disabled="!filter.is_start_date"
                                    :value-zone="timezone"
                                />
                            </div>
                        </div>

                        <div class="uk-width-1-2">
                            <label>
                                <input v-model="filter.is_due_date" class="uk-checkbox" type="checkbox">
                                {{ " " }}Req. Due Date
                            </label>
                            <div class="uk-form-controls">
                                <datetime
                                    v-model="filter.due_date"
                                    id="due_date"
                                    name="due_date"
                                    placeholder="dd/mm/yyy"
                                    input-class="uk-input"
                                    type='date'
                                    :disabled="!filter.is_due_date"
                                    :value-zone="timezone"
                                />
                            </div>
                        </div>
                    </div>
                    <div uk-grid>
                        <div class="uk-width-1-2">
                            <label>
                                <input v-model="filter.is_posted_date" class="uk-checkbox" type="checkbox">
                                {{ " " }}Posted on App
                            </label>
                            <div class="uk-form-controls">
                                <datetime
                                    v-model="filter.posted_date"
                                    id="posted_date"
                                    name="posted_date"
                                    placeholder="dd/mm/yyy"
                                    input-class="uk-input"
                                    type='date'
                                    :disabled="!filter.is_posted_date"
                                    :value-zone="timezone"
                                />
                            </div>
                        </div>

                        <div class="uk-width-1-2">
                            <label>
                                <input v-model="filter.is_updated_date" class="uk-checkbox" type="checkbox">
                                {{ " " }}Updated on App
                            </label>
                            <div class="uk-form-controls">
                                <datetime
                                    v-model="filter.updated_date"
                                    id="updated_date"
                                    name="updated_date"
                                    placeholder="dd/mm/yyy"
                                    input-class="uk-input"
                                    type='date'
                                    :disabled="!filter.is_updated_date"
                                    :value-zone="timezone"
                                />
                            </div>
                        </div>
                    </div>
                    <div uk-grid>
                        <div class="uk-width-1-2">
                            <label><input v-model="filter.is_pic" class="uk-checkbox" type="checkbox">{{" "}}PIC</label>
                            <multiselect
                                v-model="filter.pic"
                                :multiple="true"
                                placeholder="Type to search PIC"
                                track-by="_id"
                                label="fullname"
                                :searchable="true"
                                :options="picOptions"
                                :options-limit="100"
                                :disabled="!filter.is_pic"
                            >
                                <span slot="onResult">Data not found</span>
                            </multiselect>
                        </div>

                        <div class="uk-width-1-2">
                            <label><input v-model="filter.is_pic_wa" class="uk-checkbox" type="checkbox">{{ " " }}PIC Whatsapp</label>
                            <multiselect
                                v-model="filter.pic_wa"
                                :multiple="true"
                                placeholder="Type to search PIC WA"
                                track-by="_id"
                                label="name"
                                :searchable="true"
                                :options="picWhatsapp"
                                :options-limit="100"
                                :disabled="!filter.is_pic_wa"
                            >
                                <span slot="onResult">Data not found</span>
                            </multiselect>
                        </div>
                    </div>
                </section>
                <div class="uk-modal-footer uk-width-1-1 uk-flex uk-flex-between">
                    <button
                        class="uk-button"
                        style="border: 1px solid #EF5350; color: #EF5350; background: #FFF;"
                        @click="cancelFilter"
                    >
                        Clear Filter
                    </button>

                    <button
                        class="uk-button text-white"
                        :class="[!isApplyable && 'bg-disabled' || 'uk-button-primary']"
                        @click="submitFilter"
                    >
                        <template v-if="submitLoading">
                            <span uk-spinner="ratio: 0.5"></span>
                            <span class="uk-margin-small-left">Loading</span>
                        </template>
                        <template v-else>
                            Apply
                        </template>
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

import Multiselect from 'vue-multiselect';
import { Datetime } from 'vue-datetime';
import { mapGetters } from 'vuex';
import { notificationDanger } from '@/utils/notification';

export default {
    name: "ModalFilterJobVacancyV2",
    components: {
        Multiselect,
        Datetime,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        picOptions: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            submitLoading: false,
            timezone: 'Asia/Jakarta',
            filter: {
                is_pic_wa: false,
                pic_wa: [],
                is_pic: false,
                pic: [],
                is_start_date: false,
                start_date: null,
                is_due_date: false,
                due_date: null,
                is_posted_date: false,
                posted_date: null,
                is_updated_date: false,
                updated_date: null,
            },
            map: {
                pic_wa: {},
                pic: {},
            }
        };
    },
    watch: {
        submitLoading(val) {
            if (!val) this.closeModal();
        },
        'filter.is_pic'(val) {
            if (!val) this.filter.pic = [];
        },
        'filter.is_pic_wa'(val) {
            if (!val) this.filter.pic_wa = [];
        },
        'filter.is_start_date'(val) {
            if (!val) this.filter.start_date = null;
        },
        'filter.is_due_date'(val) {
            if (!val) this.filter.due_date = null;
        },
        'filter.is_posted_date'(val) {
            if (!val) this.filter.posted_date = null;
        },
        'filter.is_updated_date'(val) {
            if (!val) this.filter.updated_date = null;
        },
    },
    computed: {
        ...mapGetters({
            picWhatsapp: 'admin_job_vacancy/admin_job_vacancies',
        }),
        isApplyable() {
            return (
                (this.filter.pic_wa.length !== 0 && this.filter.is_pic_wa) ||
                (this.filter.pic.length !== 0 && this.filter.is_pic) ||
                (this.filter.start_date && this.filter.is_start_date) ||
                (this.filter.due_date && this.filter.is_due_date) ||
                (this.filter.posted_date && this.filter.is_posted_date) ||
                (this.filter.updated_date && this.filter.is_updated_date)
            );
        },
    },
    async mounted() {
        try {
            this.resetFilter();
            this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            this.mapPicData();
            this.mapFilter();
        } catch (e) {
            notificationDanger(e);
        }
    },
    methods: {
        async submitFilter() {
            try {
                this.submitLoading = true;

                await this.$emit("apply", this.parseFilter());
            } catch (e) {
                notificationDanger(e);
            } finally {
                window.UIkit.modal('#filter-job-vacancy-v2').$destroy(true);
                setTimeout(() => this.submitLoading = false, 500);
            }
        },
        async cancelFilter() {
            await this.$emit("apply", {});

            this.resetFilter();
            window.UIkit.modal('#filter-job-vacancy-v2').$destroy(true);
        },
        closeModal(timeout) {
            const params = [];
            if (timeout > 0) params.push(timeout);

            window.UIkit.modal('#filter-job-vacancy-v2').$destroy(true);
            this.$emit("close", ...params);
            this.resetFilter();
        },
        resetFilter() {
            this.filter.pic = [];
            this.filter.is_pic = false;
            this.filter.pic_wa = [];
            this.filter.is_pic_wa = false;
            this.filter.start_date = null;
            this.filter.is_start_date = false;
            this.filter.due_date = null;
            this.filter.is_due_date = false;
            this.filter.posted_date = null;
            this.filter.is_posted_date = false;
            this.filter.updated_date = null;
            this.filter.is_updated_date = false;
        },
        mapFilter() {
            const {
                pic_wa,
                is_pic_wa,
                pic,
                is_pic,
                start_date,
                is_start_date,
                due_date,
                is_due_date,
                posted_date,
                is_posted_date,
                updated_date,
                is_updated_date
            } = this.$route.query;

            this.filter.is_start_date = is_start_date === 'true';
            this.filter.start_date = start_date && this.filter.is_start_date ? start_date : null;
            this.filter.is_due_date = is_due_date === 'true';
            this.filter.due_date = due_date && this.filter.is_due_date ? due_date : null;
            this.filter.is_posted_date = is_posted_date === 'true';
            this.filter.posted_date = posted_date && this.filter.is_posted_date ? posted_date : null;
            this.filter.is_updated_date = is_updated_date === 'true';
            this.filter.updated_date = updated_date && this.filter.is_updated_date ? updated_date : null;

            this.filter.is_pic_wa = is_pic_wa === 'true';
            if (pic_wa && this.filter.is_pic_wa) {
                const pic_wa_ids = pic_wa.split(',');
                this.filter.pic_wa = pic_wa_ids.map(id => ({ _id: id, name: this.map.pic_wa[id] }));
            }

            this.filter.is_pic = is_pic === 'true';
            if (pic && this.filter.is_pic) {
                const pic_ids = pic.split(',');
                this.filter.pic = pic_ids.map(id => ({ _id: id, fullname: this.map.pic[id] }));
            }
        },
        mapPicData() {
            const maxLength = Math.max(this.picOptions.length, this.picWhatsapp.length);

            for (let i = 0; i < maxLength; i++) {
                if (this.picOptions[i]) {
                    this.map.pic[this.picOptions[i]._id] = this.picOptions[i].fullname;
                }

                if (this.picWhatsapp[i]) {
                    this.map.pic_wa[this.picWhatsapp[i]._id] = this.picWhatsapp[i].name;
                }
            }
        },
        parseFilter() {
            const filter = {};
            for (const key in this.filter) {
                if (this.filter[key] && ['pic', 'pic_wa'].includes(key)) {
                    filter[key] = this.filter[key].map(data => data._id).join(',');

                    if (filter[key] === '') delete filter[key];
                }
                else if (this.filter[key]) {
                    filter[key] = this.filter[key];
                }
            }

            return filter;
        },
    },
};
</script>
<style>
.bg-disabled {
    background-color: #979797;
    pointer-events: none;
}

.text-white {
    color: #fff !important;
}
</style>
